import * as _whichTypedArray2 from "which-typed-array";
var _whichTypedArray = _whichTypedArray2;
try {
  if ("default" in _whichTypedArray2) _whichTypedArray = _whichTypedArray2.default;
} catch (e) {}
var exports = {};
var whichTypedArray = _whichTypedArray;

/** @type {import('.')} */
exports = function isTypedArray(value) {
  return !!whichTypedArray(value);
};
export default exports;